import { useReactNativeWebView } from '@/hooks/useReactNativeWebView'
import {
    Box,
    Button,
    Container,
    Drawer,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Typography,
} from '@mui/material'
import React, { useState } from 'react'
import NextLink from 'next/link'
import { Logo } from './Logo'
import { Footory } from './Footory'
import { CurrentUser } from '../CurrentUser'
import { setCookie } from '@/utils/cookies'
import { FyButton } from '../styled/Buttons'
import { useTranslation } from 'next-i18next'
import { useUserSession } from '@/hooks/useUserSession'
import { useRouter } from 'next/router'

const PoolsTopMenu: React.FC = () => {
    const isReactNativeWebView = useReactNativeWebView()
    const { t } = useTranslation('pools')
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [session] = useUserSession()
    const router = useRouter()

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return
        }

        setDrawerOpen(open)
    }

    return (
        <Box sx={{ marginTop: '10px' }}>
            {!isReactNativeWebView && (
                <Container>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                        <Link href="/pools" component={NextLink}>
                            <Box width={32} height={32}>
                                <Logo logoColor={'#000'} />
                            </Box>
                        </Link>
                        <Box
                            width={90}
                            sx={{
                                display: {
                                    xs: 'none',
                                    md: 'block',
                                },
                            }}
                        >
                            <Link href="/pools" component={NextLink}>
                                <Footory />
                            </Link>
                        </Box>
                        <Box
                            display="flex"
                            gap="10px"
                            flexDirection="row"
                            alignItems="center"
                            sx={{ display: { xs: 'none', md: 'flex' } }}
                        >
                            <Typography>&#x2022;</Typography>
                            <NextLink passHref href="/pools/learn-more">
                                <Button color="poolEventHeaderPrimary">
                                    {t('globalPoolLayout_privatePools')}
                                </Button>
                            </NextLink>
                            <Typography>&#x2022;</Typography>
                            <NextLink passHref href="/pools/globals/learn-more">
                                <Button color="poolEventHeaderPrimary">
                                    {t('globalPoolLayout_globalPool')}
                                </Button>
                            </NextLink>
                            <Typography>&#x2022;</Typography>
                            {/* locales */}
                            <FyButton
                                key={router.locale === 'en' ? 'fr' : 'en'}
                                onClick={() => {
                                    const locale = router.locale === 'en' ? 'fr' : 'en'
                                    setCookie('NEXT_LOCALE', locale, 30)
                                    router.push(router.asPath, undefined, {
                                        locale,
                                    })
                                }}
                            >
                                {router.locale === 'en' ? 'français' : 'english'}
                            </FyButton>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                marginLeft: 'auto',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '10px',
                            }}
                        >
                            {session && (
                                <NextLink passHref href="/pools/me">
                                    <FyButton variant="contained" sx={{ fontSize: '14px' }}>
                                        {t('buttonMyPools')}
                                    </FyButton>
                                </NextLink>
                            )}
                            <CurrentUser />
                        </Box>
                    </Box>

                    <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                        <Box
                            sx={{
                                width: 250,
                            }}
                            role="presentation"
                            onClick={toggleDrawer(false)}
                            onKeyDown={toggleDrawer(false)}
                        >
                            <List>
                                <ListItem disablePadding>
                                    <NextLink href="/pools/me" passHref>
                                        <ListItemButton>
                                            <ListItemText primary={t('buttonMyPools')} />
                                        </ListItemButton>
                                    </NextLink>
                                </ListItem>
                                <ListItem disablePadding>
                                    <NextLink href="/pools/learn-more" passHref>
                                        <ListItemButton>
                                            <ListItemText primary={'Pools privés'} />
                                        </ListItemButton>
                                    </NextLink>
                                </ListItem>
                                <ListItem disablePadding>
                                    <NextLink href="/pools/globals/learn-more" passHref>
                                        <ListItemButton>
                                            <ListItemText primary={'Pool Global'} />
                                        </ListItemButton>
                                    </NextLink>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton
                                        onClick={() => {
                                            const locale = router.locale === 'en' ? 'fr' : 'en'
                                            setCookie('NEXT_LOCALE', locale, 30)
                                            router.push(router.asPath, undefined, {
                                                locale,
                                            })
                                        }}
                                    >
                                        <ListItemText
                                            primary={router.locale === 'en' ? 'Français' : 'English'}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Box>
                    </Drawer>
                </Container>
            )}
        </Box>
    )
}

export default PoolsTopMenu
