import { useReactNativeWebView } from '@/hooks/useReactNativeWebView'
import { Footer } from '@/PageLayout'
import { Box, Container, Link } from '@mui/material'
import { useTranslation } from 'next-i18next'
import React from 'react'

const PoolsFooter: React.FC = () => {
    const isReactNativeWebView = useReactNativeWebView()
    const { t } = useTranslation('pools')

    return (
        <>
            <Box marginTop="100px" paddingBottom="50px" paddingTop="50px" bgcolor="#111" color="#fff">
                {!isReactNativeWebView && <Footer />}
                <Container sx={{ marginY: '30px' }}>
                    <Box textAlign="center">{t('madeWithLove')}</Box>
                </Container>
                <Container sx={{ marginTop: '30px' }}>
                    <Box textAlign="center">
                        <Link
                            fontSize="12px"
                            color="secondary"
                            href="https://www.flaticon.com/authors/guillem-rodriguez"
                            title="champions icons"
                        >
                            {t('iconsAttribution')}
                        </Link>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default PoolsFooter
