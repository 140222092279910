import { CssBaseline, ThemeProvider, useTheme } from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import { getDefaultPoolEventTheme, getPoolEventThemeData } from '@/utils/pools_themes'
import Head from 'next/head'
import { PoolEventResponse, PoolResponse } from '@/utils/pool_types'
import { LINEAR_GRADIENT } from '@/utils/poolStyles'
import { getCookieConsentValue } from 'react-cookie-consent'
import PoolsTopMenu from './PoolsTopMenu'
import PoolsFooter from './PoolsFooter'

type PoolLayoutProps = PropsWithChildren<{
    pool?: PoolResponse
    poolEvent?: PoolEventResponse
}>

export const PoolLayout: FC<PoolLayoutProps> = (props) => {
    const theme = useTheme()
    const { pool, poolEvent, children } = props

    const poolEventThemeData = getPoolEventThemeData(pool?.event || poolEvent) ?? {
        headerBackgroundColor: '#fff',
        headerLogoColor: '#000',
        headerTextColor: '#000',
        primaryMainColor: '#09c269',
        headerPrimaryMainColor: '#000',
        primaryGradient: LINEAR_GRADIENT,
    }

    const poolTheme = getDefaultPoolEventTheme(theme, poolEventThemeData)

    const client = global.window
    const cookieConsent = client && getCookieConsentValue()

    return (
        <>
            <Head>
                <meta property="og:site_name" content="Footory Pools" />
                <meta
                    property="og:image"
                    content={`${process.env.NEXT_PUBLIC_BASE_URL}${
                        poolTheme.ogImage ?? '/images/og-image-1200x630-2.png'
                    }`}
                />
                {client && cookieConsent === 'true' && (
                    <script>
                        {window.gtag('consent', 'update', {
                            ad_storage: 'granted',
                            ad_user_data: 'granted',
                            ad_personalization: 'granted',
                            analytics_storage: 'granted',
                        })}
                    </script>
                )}
            </Head>
            <ThemeProvider theme={poolTheme.theme}>
                <CssBaseline />
                <PoolsTopMenu />
                {children}
                <PoolsFooter />
            </ThemeProvider>
        </>
    )
}
